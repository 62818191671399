import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import ImageGalleryWithLightbox from "../components/imageGalleryWithLightbox"

const EnGuete = ({data}) => {

  // Single Image Data
  const images = {
    image1: data.image1.childImageSharp.fluid,
    image2: data.image2.childImageSharp.fluid,
    image3: data.image3.childImageSharp.fluid,
    image4: data.image4.childImageSharp.fluid,
  }

  return (
    <Layout title="En guete" slogan="Kulinarisch verwöhnt werden - tagtäglich" headerImage={data.image2.childImageSharp.fluid}>
      <div className="container-two-cl--small">
        <div className="container-two-cl__images">
          <ImageGalleryWithLightbox images={Object.values(images)} />
        </div>
        <div>
          <p>
            <b>En guete!</b> Das Essen ist ein wesentliches Bedürfnis im Leben jedes Einzelnen. Unser Credo ist deshalb eine gesunde Schweizerküche, liebevoll zubereitet aus regionalen Frischeprodukten.  Die Küche des Altersheims Bannau bietet täglich marktfische und abwechslungsreiche Menüs, die mit Fachkompetenz und Liebe zubereitet werden. Die erlesenen Gerichte geniessen Sie mit einem von Ihnen ausgesuchten Getränk, wobei es uns ein grosses Anliegen ist, die Menügestaltung in Einklang mit den Vorlieben unserer Gästen zu bringen. Eine saisonale und ausgewogene Küche ist bei uns so selbstverständlich, wie die Erfüllung individueller Wünsche, sowie einem zuvorkommenden Service.</p>
            
            <h4>Essen für Senioren/innen</h4>
            <p>Wir bieten allen Seniorinnen und Senioren aus Weinfelden und Umgebung die Möglichkeit bei uns Mittag zu essen, selbst wenn man kein Bewohner ist. Es spielt keine Rolle, ob Sie nur zwischendurch bei uns essen kommen oder einen regelmässigen Besuch anmelden möchten. Dann haben Sie sich nicht um Einkäufe und langes Kochen zu sorgen, sondern sind bei uns in besten Hände mit köstlichen Leckereien aufgehoben. Bitte melden Sie sich bei unserem Küchenchef (siehe unten).</p>
            
            <h4>Essen mit Angehörigen</h4>
            <p>Wenn Sie mit Ihren Angehörigen oder Gästen zusammen zu Mittag essen möchten, dann reservieren wir Ihnen selbstverständlich einen Tisch im Speisesaal. Bitte beachten Sie, dass das Küche-Team um eine frühzeitige Anmeldung dankbar wäre (1 – 2 Tage voraus). Bei Interesse können Sie sich telefonisch an unseren Küchenchef Martin Knöpfli melden:
            <br/><br/>Martin Knöpfli
            <br/>Tel: <a href="tel:0716221411" style={{textDecoration: 'none'}}>071 622 14 11</a>
          </p>
        </div>
      </div>

    </Layout>
  ) 
}

export default EnGuete

export const pageQuery = graphql`
  query {
    image1: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-teller1.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    image2: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-teller2.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    image3: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-teller3.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    image4: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-teller4.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
  }
`